import { getBody, getUserAgent } from '../util';

export const getURLQuery = () : { [ key : string ] : string } => {
    const queryString = new URLSearchParams(window.location.search);
    return Object.fromEntries(queryString.entries());
};

export const getURLQueryParam = (name : string) : string | undefined => {
    const queryParams = getURLQuery();
    return queryParams[name];
};

let openKeyboardInput : HTMLInputElement | undefined;

export const openKeyboard = () : void => {
    if (!openKeyboardInput) {
        openKeyboardInput = document.createElement('input');
        openKeyboardInput.style.position = 'fixed';
        openKeyboardInput.style.visibility = 'invisible';
        openKeyboardInput.style.opacity = '0';
        openKeyboardInput.style.pointerEvents = 'none';
        getBody().appendChild(openKeyboardInput);
    }

    openKeyboardInput.style.top = `${ window.scrollY + Math.round(window.innerHeight / 2) }px`;
    openKeyboardInput.style.left = `-10000px`;

    openKeyboardInput.focus({
        preventScroll: true
    });
};

export const isBot = () : boolean => {
    const userAgent = getUserAgent();

    if (!userAgent) {
        return false;
    }

    return (/bot|crawl|spider|wget|slurp|mediapartners-google/i).test(userAgent);
};
